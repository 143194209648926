'use strict';

angular.module('kljDigitalLibraryApp')
    .service('HomeService', ["$q", "common", "appConfig", function($q, common, appConfig) {

        this.getNewsMarquee = function(limit) {


            return common.callApi('GET', '/api/news/getAllNews/' + limit, appConfig.EMPTY_STRING, appConfig.CONTENT_TYPE_JSON)

            .then(function(response) {

                return response.data;
            })

        }
    }]);